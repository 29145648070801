import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../axios/interceptor';
import openNotification from '../../../components/notification';

const initialState = {
    clientsList: [],
    client: {},
    totalRecords: 0,
    loading: false,
    error: null,
    isUpdating: false,
    isSuccess: false,
};

export const slice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        getClientsList: (state, action) => {
            state.client = {};
            state.isUpdating = false;
            state.clientsList = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setIsSuccess: (state, action) => {
            state.client = {};
            state.isSuccess = action.payload;
        },
        setTotalrecords: (state, action) => {
            state.totalRecords = action.payload;
        },
        getClient: (state, action) => {
            state.client = action.payload;
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload;
        },
    },
});

export const getClientsListData = createAsyncThunk('getClientsListData', (filterData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/client/get`, filterData).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getClientsList(response.data.records))
            dispatch(setTotalrecords(response.data.totalRecords))
        } else {
            dispatch(getClientsList([]));
            dispatch(setTotalrecords(0))
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const createClient = createAsyncThunk('createClient', (userData, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/client/insert`, userData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getClientsListData({ search: '', sortBy: '', order: '', page: 1, limit: 10 }))
            dispatch(setIsSuccess(true));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getByIdClient = createAsyncThunk('getByIdClient', (id, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/client?id=${id}`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getClient(response.data.record))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ type: 'error', message: error.message });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const updateClient = createAsyncThunk('updateClient', (clientData, { dispatch }) => {
    dispatch(setLoading(true));
    axios.post(`/client/update`, clientData).then((response) => {
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getClientsListData({ search: '', sortBy: '', order: '', page: 1, limit: 10 }))
            dispatch(setIsSuccess(true))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const deleteClient = createAsyncThunk('deleteClient', (object, { dispatch }) => {
    dispatch(setLoading(true))
    axios.post(`/client/status?id=${object.id}`).then((response) => {
        delete object.id
        if (response.data.isSuccess) {
            openNotification({ message: response.data.message, type: 'success' });
            dispatch(getClientsListData(object));
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// Action creators are generated for each case reducer function
export const { setError, setLoading, getClient, setIsUpdating, setIsSuccess, getClientsList, setTotalrecords } = slice.actions;

export default slice.reducer;
