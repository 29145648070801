export const NumberRegex = /^\d{10}$/;
export const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
export const PasswordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}:'<>?])[A-Za-z\d!@#$%^&*()_+{}:'<>?]{8,}$/;


export const permission = {
    dashboard: ['administrator', 'client'],
    users: ['administrator', 'client'],
    userType: ['administrator', 'client'],
    clients: ['administrator'],
    packages: ['administrator'],
}