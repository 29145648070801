import React, { Fragment, useEffect, useState } from 'react';

import { Button, Flex, Form, Row, Col, Input, Typography, Select, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { createClient, getByIdClient, getClient, setIsSuccess, setIsUpdating, updateClient } from './redux/slice';
import { getPackagesListing, getUserTypeListing } from '../../redux/slice/global';

import BackIcon from '../../components/icon/BackIcon';

const { Title, Text } = Typography;

const AddUpdateClient = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { id } = useParams();
    const { client, listing } = useSelector(state => state);
    const [modalType, setModalType] = useState(false);
    const [packagesList, setPackagesList] = useState([]);
    const [userTypeListing, setUserTypeListing] = useState([]);

    // Get User Types
    useEffect(() => {
        getPackageLists();
        dispatch(getUserTypeListing())
    }, [])

    useEffect(() => {
        setUserTypeListing(listing.listing.userType)
    }, [listing?.listing?.userType])

    const getPackageLists = () => {
        dispatch(getPackagesListing());
    };

    useEffect(() => {
        if (listing.listing?.packages?.length > 0) {
            setPackagesList(listing.listing.packages);
        }
    }, [listing.listing.packages]);

    const handleOnFinish = (values) => {
        if (id) {
            dispatch(setIsUpdating(false));
        }
        dispatch(getClient({ ...values, maxUsers: Number(values.maxUsers) }));
    };

    useEffect(() => {
        if (id) {
            setModalType(true);
            dispatch(setIsUpdating(true));
            dispatch(getByIdClient(id));
        }
    }, [id]);

    // Create and Update Client
    useEffect(() => {
        if (Object.keys(client.client).length > 0) {
            if (!modalType) {
                dispatch(createClient(client.client));
            } else {
                if (client.isUpdating) {
                    form.setFieldsValue({ ...client.client, maxUsers: client.client?.maxUsers });
                } else {
                    dispatch(updateClient({ ...client.client, id: id }));
                }
            }
        }
        if (client.isSuccess) {
            handleClear();
            navigate('/client');
            setModalType(false);
            dispatch(setIsSuccess(false));
        }
    }, [client.isUpdating, client.client, client.isSuccess]);

    // Clear from 
    const handleClear = () => {
        form.resetFields();
    };

    return (
        <Fragment>
            <Flex vertical gap={24}>
                <Flex justify='space-between' align='center' gap={6} className='w-100' wrap={true}>
                    <Button
                        type='text'
                        icon={<BackIcon role={'button'} />}
                        className='bg-white paddingX-3'
                        onClick={() => navigate('/client')}
                    />
                </Flex>
                <Flex vertical gap={8} className='bg-white padding-2'>
                    <Flex>
                        <Title level={4} type='success'>{id ? 'Edit Client' : 'Add New Client'}</Title>
                    </Flex>
                    <Divider />
                    <Form
                        form={form}
                        name='client'
                        colon={true}
                        requiredMark={(label, isRule) => {
                            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                        }}
                        initialValues={{ userType: '66ded08f2dcb52f1b7a4cadb' }}
                        layout='vertical'
                        onFinish={handleOnFinish}
                    >
                        <Row gutter={18}>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='company'
                                    label='Company name'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a company name!',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='Company Name'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='email'
                                    label='Admin email'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter email!'
                                        },
                                        {
                                            type: 'email',
                                            message: 'Please enter valid email!'
                                        }
                                    ]}
                                >
                                    <Input
                                        placeholder='Admin Email'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='userType'
                                    label='User type'
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder='Please select user type'
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        disabled
                                        options={userTypeListing?.filter(i => i.label.toLowerCase() === 'client')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='package'
                                    label='Select Package'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a package!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder='Please select package'
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={packagesList?.length > 0 && packagesList}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='maxUsers'
                                    label='Maximum User'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a maximum user!',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='Maximum User'
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Flex justify='flex-end' gap={10} wrap={true}>
                            <Button danger type='primary' htmlType='button' onClick={handleClear}>Clear All</Button>
                            <Button type='primary' htmlType='submit' loading={client?.loading}>{id ? 'Submit' : 'Invite send'}</Button>
                        </Flex>
                    </Form>
                </Flex>
            </Flex>
        </Fragment>
    );
};

export default AddUpdateClient;
