import React, { Fragment } from 'react';

import {
    Route,
    Routes,
    Navigate,
    Outlet,
    useLocation
} from 'react-router-dom';

import { privateRoutes, publicRoutes } from '../navigations/navigations';

import Wrapper from '../layout/Wrapper';
import NotFound from '../pages/404';

const RequireAuth = () => {
    const location = useLocation();

    const decodedCookie = decodeURIComponent(document.cookie);
    const getToken = decodedCookie.split('=')[1];

    if (!getToken) {
        return <Navigate to='/user/authentication/sign-in' replace />;
    }

    if (getToken && (location.pathname === '/user/authentication/sign-in' || location.pathname === '/')) {
        return <Navigate to='/dashboard' replace />;
    }
    return <Outlet />;
};

const CheckIsLoggedIn = ({ element }) => {
    const location = useLocation();

    const decodedCookie = decodeURIComponent(document.cookie);
    const getToken = decodedCookie.split('=')[1];

    if (!getToken) {
        return element;
    }
    if (getToken && (publicRoutes.filter((e) => e.to.includes(location.pathname.split('/')?.[1])) || location.pathname === '/')) {
        return <Navigate to='/dashboard' replace />;
    }
    return <Outlet />;
};

const AppRouter = () => {
    return (
        <Routes>
            <Route
                path='*'
                element={
                    (
                        <NotFound />
                    )
                }
            />
            {
                publicRoutes.map((routes) => {
                    return (
                        <Route
                            key={routes.to}
                            element={<CheckIsLoggedIn element={routes.element} />}
                            path={routes.to}
                        />
                    );
                })
            }
            <Route element={<RequireAuth />} path='/'>
                {
                    privateRoutes.map((route) => {
                        return (
                            <Fragment key={route.to}>
                                {
                                    route.children?.length <= 0 &&
                                    <Route
                                        key={route.to}
                                        element={<Wrapper>{route.element}</Wrapper>}
                                        path={route.to}
                                    />
                                }
                                {
                                    route.children?.length > 0 &&
                                    <Route
                                        key={route.to}
                                        path={route.to}
                                    >
                                        {
                                            route.children?.map((children) => {
                                                return (
                                                    <Route
                                                        key={children.to}
                                                        element={<Wrapper>{children.element}</Wrapper>}
                                                        path={children.to}
                                                    />
                                                )

                                            })
                                        }
                                    </Route>
                                }
                            </Fragment>
                        );
                    })
                }
            </Route>
        </Routes>
    );
};

export default AppRouter;
