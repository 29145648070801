import React, { Fragment, useEffect, useState } from 'react';

import { Button, Col, Collapse, Flex, Form, Input, Modal, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { createUserType, deleteUserType, getUserType, getUserTypesListData, setIsSuccess } from './redux/slice';

import DataTable from '../../components/data-table';

import ExpandIcon from '../../components/icon/ExpandIcon';
import BackIcon from '../../components/icon/BackIcon';
import EditIcon from '../../components/icon/EditIcon';
import DeleteIcon from '../../components/icon/DeleteIcon';
import SearchIcon from '../../components/icon/SearchIcon';

const { Title, Text, Paragraph } = Typography;

const UserType = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { userType } = useSelector(state => state)

    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('');
    const [totalRecord, setTotalRecord] = useState(0)
    const [userTypeList, setUserTypeList] = useState([])
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('')
    const [modalType, setModalType] = useState(false)

    useEffect(() => {
        getUserTypeList()
    }, [page, pageSize, order, sortBy, search])

    useEffect(() => {
        setUserTypeList(userType.userTypesList)
        setTotalRecord(userType.totalRecords)
        if (Object.keys(userType.userType).length > 0) {
            if (modalType) {
                dispatch(createUserType(userType.userType))
                setModalType(false)
            }
        }
        if (userType.isSuccess) {
            handleClear()
            dispatch(setIsSuccess(false))
        }
    }, [userType.userTypesList, userType.userType, userType.isSuccess, userType.totalRecords])

    const getUserTypeList = () => {
        dispatch(getUserTypesListData({
            sortBy: sortBy,
            order: order,
            search: search?.trim(),
            page: page,
            limit: pageSize,
        }))
    }

    const handleOnFinish = (values) => {
        setModalType(true)
        dispatch(getUserType(values))
    };

    const handleClear = () => {
        form.resetFields();
        setIsDeleteModal(false);
        setDeleteId('')
    };

    const customExpandIcon = ({ isActive }) => {
        return <ExpandIcon
            style={{
                transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
            }}
            role='button'
        />
    };

    const handleDeleteModal = (id) => {
        setIsDeleteModal(!isDeleteModal)
        setDeleteId(id)
    }

    const handleSortFunction = (c, type) => {
        setSortBy(type)
        setOrder(c === 'descend' ? 'desc' : 'asc');
    };

    const handleOnChange = (page, pageSize) => {
        setPageSize(pageSize);
        setPage(page);
    };

    const items = [
        {
            key: '1',
            label: <Title level={4} type='success' className='text-primary'>Add User Type</Title>,
            children: <Fragment>
                <Form
                    form={form}
                    className='user-filter'
                    name='user'
                    colon={false}
                    requiredMark={false}
                    layout='vertical'
                    onFinish={handleOnFinish}
                >
                    <Row gutter={18}>
                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24} >
                            <Form.Item
                                name='name'
                                label='User Type'

                                rules={[{ required: true, message: 'User type is required!' }]}
                            >
                                <Input placeholder='Please Enter' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Flex justify='flex-end' gap={10} wrap={true}>
                        <Button danger type='primary' htmlType='button' onClick={handleClear}>Clear All</Button>
                        <Button type='primary' htmlType='submit'>Submit</Button>
                    </Flex>
                </Form>
            </Fragment>,
        }
    ];

    const columns = [
        {
            title: 'User Types',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <Text>{text}</Text>,
            sorter: (_, __, order) => handleSortFunction(order, 'name')
        },
        {
            title: <Flex justify='center'><Text>Action</Text></Flex>,
            key: 'action',
            width: '100px',
            render: (_, record) => {
                return (
                    < Flex justify='center'>
                        <Button
                            type='text'
                            className='padding-1'
                            onClick={() => navigate(`/user/type/edit/${record?._id}`)}>
                            <EditIcon role='button' />
                        </Button>
                        <Button
                            type='text'
                            className='padding-1'>
                            <DeleteIcon
                                role='button'
                                onClick={() => handleDeleteModal(record?._id)}
                            />
                        </Button>
                    </Flex >
                );
            },
        },
    ];

    return (
        <Fragment>
            <Flex vertical gap={12} className='user-management'>
                <Button
                    type='text'
                    icon={<BackIcon role={'button'} />}
                    className='bg-white paddingX-3'
                />
                <Flex className='filter-margin-top'>
                    <Collapse
                        items={items}
                        defaultActiveKey={['1']}
                        className='w-100'
                        bordered={false}
                        expandIcon={customExpandIcon}
                        expandIconPosition={'end'}
                    />
                </Flex>
                <Flex vertical className='bg-white rounded-top-1'>
                    <Flex className='padding-1'>
                        <Row className='w-100'>
                            <Col xxl={8} xl={10} lg={12} md={14} sm={24} xs={24}>
                                <Input
                                    variant='filled'
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    prefix={<SearchIcon color='#000' />}
                                />
                            </Col>
                        </Row>
                    </Flex>
                    <DataTable
                        isPagination={true}
                        showSorterTooltip={false}
                        total={totalRecord}
                        pageSize={pageSize}
                        currentPage={page}
                        columns={columns}
                        data={userTypeList}
                        handleOnChange={handleOnChange}
                        rowKey={(record) => record?._id}
                    />
                </Flex>
            </Flex>

            <Modal
                centered
                open={isDeleteModal}
                closable={false}
                width={380}
                footer={[
                    <Button key='cancel' type='default' onClick={handleDeleteModal}>
                        Cancel
                    </Button>,
                    <Button
                        danger type='primary' htmlType='button'
                        onClick={() => {
                            dispatch(deleteUserType({
                                id: deleteId,
                                sortBy: '',
                                order: '',
                                search: '',
                                page: page,
                                limit: pageSize,
                            }))
                            setIsDeleteModal(false)
                            setDeleteId('')
                        }}
                    >
                        Delete
                    </Button>,
                ]}
            >
                <Flex vertical align='center' justify='center'>
                    <Title level={2} className='paddingY-2' type='danger'>Are you sure?</Title>
                    <Paragraph type='danger'>You want to delete this user type?</Paragraph>
                </Flex>
            </Modal>
        </Fragment>
    );
};

export default UserType;
