import { configureStore } from '@reduxjs/toolkit';
import userSlice from '../pages/user/redux/slice';
import listingSlice from './slice/global';
import authSlice from '../pages/auth/redux/slice';
import userTypeSlice from '../pages/user-type/redux/slice';
import packageSlice from '../pages/packages/redux/slice';
import clientSlice from '../pages/client/redux/slice';

const store = configureStore({
    reducer: {
        listing: listingSlice,
        auth: authSlice,
        user: userSlice,
        userType: userTypeSlice,
        packages: packageSlice,
        client: clientSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export default store;
