import React, { Fragment, useEffect, useState } from 'react';

import { Button, Flex, Form, Row, Col, Input, Typography, Select, Divider } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getUserTypeListing } from '../../redux/slice/global';
import { createUser, getByIdUser, getUser, setIsSuccess, setIsUpdating, updateUser } from './redux/slice';

import BackIcon from '../../components/icon/BackIcon';
import { jwtDecode } from 'jwt-decode';

const { Title, Text } = Typography;

const AddUpdateUser = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const { id } = useParams();
    const { user, listing } = useSelector(state => state);
    const [loginUser, setLoginUser] = useState({});
    const [modalType, setModalType] = useState(false);
    const [userTypesList, setUserTypesList] = useState([]);

    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value?.split(`; ${name}=`);
            if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
            return null;
        }

        const token = getCookie('token');
        if (token) {
            const user = jwtDecode(token);
            setLoginUser(user);
        }
    }, [])

    const handleOnFinish = (values) => {
        if (id) {
            dispatch(setIsUpdating(false));
        }
        dispatch(getUser({
            ...values,
            address: { streetNo: values?.streetNo, city: values?.city, zip: values?.zip },
            ...(loginUser.role === userTypesList?.find(x => x.label === 'Client')?.label) && { client: loginUser._id }
        }));
    }

    useEffect(() => {
        getUserTypeLists();
    }, [])

    const getUserTypeLists = () => {
        dispatch(getUserTypeListing());
    }

    useEffect(() => {
        if (listing.listing?.userType?.length > 0) {
            setUserTypesList(listing.listing.userType);
        }
    }, [listing.listing.userType])

    useEffect(() => {
        if (id) {
            setModalType(true);
            dispatch(setIsUpdating(true));
            dispatch(getByIdUser(id));
        }
    }, [id]);

    useEffect(() => {
        if (Object.keys(user.user).length > 0) {
            if (!modalType) {
                dispatch(createUser(user.user));
            } else {
                if (user.isUpdating) {
                    form.setFieldsValue({ ...user.user, streetNo: user?.user?.address?.streetNo, city: user?.user?.address?.city, zip: user?.user?.address?.zip });
                } else {
                    dispatch(updateUser({ ...user.user, id: id }));
                }
            }
        }
        if (user.isSuccess) {
            handleClear();
            navigate('/user');
            setModalType(false);
            dispatch(setIsSuccess(false));
        }
    }, [user.isUpdating, user.user, user.isSuccess]);

    const handleClear = () => {
        form.resetFields();
    };

    return (
        <Fragment>
            <Flex vertical gap={24} className='user-management'>
                <Flex justify='space-between' align='center' gap={6} className='w-100' wrap={true}>
                    <Button
                        type='text'
                        icon={<BackIcon role={'button'} />}
                        className='bg-white paddingX-3'
                        onClick={() => navigate('/user')}
                    />
                </Flex>
                <Flex vertical gap={8} className='bg-white padding-2'>
                    <Flex>
                        <Title level={4} type='success'>{id ? 'Edit User' : 'Add User'}</Title>
                    </Flex>
                    <Divider />
                    <Form
                        form={form}
                        name='user'
                        colon={true}
                        requiredMark={(label, isRule) => {
                            return <Text>{label}<Text type='danger'>{isRule?.required && '*'}</Text></Text>
                        }}
                        layout='vertical'
                        onFinish={handleOnFinish}
                    >
                        <Row gutter={18}>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24} >
                                <Form.Item
                                    name='username'
                                    label='Username'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Username is required!',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='Username'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='firstName'
                                    label='First name'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Firstname is required!',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='Firstname'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='lastName'
                                    label='Last name'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Lastname is required!',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='Lastname'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='phone'
                                    label='Phone number'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Phone number is required!',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='Phone number'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='email'
                                    label='Email'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Email is required!',
                                        },
                                        {
                                            type: 'email',
                                            message: 'Invalid email!'
                                        }
                                    ]}
                                >
                                    <Input
                                        placeholder='Email'
                                    />
                                </Form.Item>
                            </Col>
                            <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Form.Item
                                    name='userType'
                                    label='User type'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'User type is required!',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder='Please select user type'
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={userTypesList}
                                    />
                                </Form.Item>
                            </Col>
                            <Title level={5} className='paddingX-1'>Address</Title>
                            <Row className='w-100 padding-1' gutter={18}>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='streetNo'
                                        label='Street Name & Number'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Street number is required!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            showCount={false}
                                            placeholder='Street number'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='city'
                                        label='City'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'City is required!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder='City'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name='zip'
                                        label='Zip'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Zipcode is required!',
                                            },
                                            {
                                                pattern: new RegExp(/^[0-9]+$/),
                                                message: 'Invalid zipcode!'
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder='Zip'
                                        />
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Row>
                        <Flex justify='flex-end' gap={10} wrap={true}>
                            <Button danger type='primary' htmlType='button' onClick={handleClear}>Clear All</Button>
                            <Button type='primary' htmlType='submit' loading={user?.loading}>{id ? 'Apply New' : 'Invite send'} </Button>
                        </Flex>
                    </Form>
                </Flex>
            </Flex>
        </Fragment>
    );
};

export default AddUpdateUser;
