import React, { Fragment, useEffect, useState } from 'react';

import { Button, Col, Flex, Input, Row, Tag, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DataTable from '../../components/data-table';

import { deleteUser, getUsersListData, setLoginUserId, setLoginUserRole } from './redux/slice';
import { checkInvitation, resendInvitation, setInvitationMsg, setIsInvitation, setIsSuccess } from '../auth/redux/slice';

import CommonPopconfirm from '../../components/custom-popconfirm';

import DeleteIcon from '../../components/icon/DeleteIcon';
import EditIcon from '../../components/icon/EditIcon';
import EmailIcon from '../../components/icon/EmailIcon';
import CloseIcon from '../../components/icon/CloseIcon';
import SearchIcon from '../../components/icon/SearchIcon';

import { lightPrimary, lightSecondary, lightYellow } from '../../utils/theme/config';
import { jwtDecode } from 'jwt-decode';
import { getUserTypeListing } from '../../redux/slice/global';

const { Title, Text } = Typography;

const User = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user, auth, listing } = useSelector(state => state);

    const [pageSize, setPageSize] = useState(10);
    const [loginUser, setLoginUser] = useState({});
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState('');
    const [order, setOrder] = useState('');
    const [userList, setUserList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [search, setSearch] = useState('');
    const [userId, setUserId] = useState("")
    const [userTypesList, setUserTypesList] = useState([]);
    const [visiblePopconfirm, setVisiblePopconfirm] = useState({});

    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value?.split(`; ${name}=`);
            if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
            return null;
        }

        const token = getCookie('token');
        if (token) {
            const user = jwtDecode(token);
            setLoginUser(user);
            dispatch(setLoginUserId(user._id))
            dispatch(setLoginUserRole(user.role))
        }

        dispatch(getUserTypeListing());
    }, [])

    useEffect(() => {
        setUserTypesList(listing.listing.userType);
    }, [listing.listing.userType])

    useEffect(() => {
        getUsersList();
    }, [pageSize, page, sortBy, order, search, loginUser]);

    const getUsersList = () => {
        dispatch(getUsersListData({
            sortBy: sortBy,
            order: order,
            search: search?.trim(),
            page: page,
            limit: pageSize,
            ...(user.loginUserRole === 'Client') && { client: user.loginUserId }
        }))
    };

    useEffect(() => {
        setUserList(user.usersList);
        setTotalRecords(user.totalRecords);
    }, [user.usersList, user.totalRecords, search]);

    const handleOnChange = (page, pageSize) => {
        setPageSize(pageSize);
        setPage(page);
    };

    const handleSortFunction = (c, type) => {
        setSortBy(type)
        setOrder(c === 'descend' ? 'desc' : 'asc');
    };

    useEffect(() => {
        document.documentElement.scrollTop = document.documentElement.clientHeight;
        document.documentElement.scrollLeft = document.documentElement.clientWidth;
    }, []);

    const handleCheckInvitation = (userId) => {
        setUserId(userId);
        dispatch(checkInvitation(userId))
            .then((result) => {
                if (result.meta.requestStatus === 'fulfilled') {
                    if (Object.keys(auth?.invitationMsg).length > 0) {
                        setVisiblePopconfirm(prevState => ({
                            ...prevState,
                            [userId]: true
                        }));
                    }
                }
            });
    };

    const handleCancel = (userId) => {
        setVisiblePopconfirm(prevState => ({
            ...prevState,
            [userId]: false
        }));
        setUserId("");
    };

    const handleConfirm = (userId) => {
        dispatch(resendInvitation(userId));
        dispatch(setInvitationMsg({}));
        handleCancel(userId);
    };

    useEffect(() => {
        if (Object.keys(auth?.invitationMsg).length > 0 && auth?.isInvitation && auth.isSuccess) {
            setVisiblePopconfirm(prevState => ({
                ...prevState,
                [userId]: true
            }));
            setUserId("");
            dispatch(setIsSuccess(false));
            dispatch(setIsInvitation(false));
        }
    }, [auth?.invitationMsg, auth?.isInvitation, auth.isSuccess]);

    const columns = [
        {
            title: 'Firstname',
            dataIndex: 'firstName',
            width: '150px',
            key: 'firstName',
            sorter: (_, __, order) => handleSortFunction(order, 'firstName')
        },
        {
            title: 'Lastname',
            dataIndex: 'lastName',
            width: '150px',
            key: 'lastname',
            sorter: (_, __, order) => handleSortFunction(order, 'lastName')
        },
        {
            title: 'Email',
            dataIndex: 'email',
            width: '200px',
            key: 'email',
            sorter: (_, __, order) => handleSortFunction(order, 'email')
        },
        {
            title: 'Phone number',
            dataIndex: 'phone',
            width: '200px',
            key: 'phone number',
            sorter: (_, __, order) => handleSortFunction(order, 'phone')
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            width: '180px',
            sorter: (_, __, order) => handleSortFunction(order, 'username')
        },
        {
            title: 'Usertype',
            dataIndex: 'userType',
            key: 'userType',
            width: '250px',
            sorter: (_, __, order) => handleSortFunction(order, 'userType'),
            render: (type) => {
                return <Tag color={lightPrimary} className='text-center'>{type}</Tag>
            }
        },
        {
            title: 'Street name & no.',
            dataIndex: 'streetNo',
            key: 'streetNo',
            width: '250px',
            sorter: (_, __, order) => handleSortFunction(order, 'streetNo'),
            render: (_, record) => {
                return record?.address?.streetNo && record?.address?.streetNo?.toString()
                    ?.split(' ')
                    ?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    ?.join(' ');
            }
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'city'),
            render: (_, record) => {
                const city = record?.address?.city && record.address.city
                    ?.split(' ')
                    ?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    ?.join(' ');
                return city;
            }
        },
        {
            title: 'Zip',
            dataIndex: 'zip',
            key: 'zip',
            width: '200px',
            sorter: (_, __, order) => handleSortFunction(order, 'zip'),
            render: (_, record) => {
                return record?.address?.zip
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '250px',
            sorter: (_, __, order) => handleSortFunction(order, 'status'),
            render: (_, record) => {
                return (
                    <Fragment>
                        {
                            Number(record.status) === 1 ?
                                <Tag color={lightPrimary} className='text-center'>Active</Tag> :
                                Number(record.status) === 2 ?
                                    <Tag color={lightYellow} className='text-center'>Waiting For Confirmation</Tag> :
                                    <Tag color={lightSecondary} className='text-center'>Deactivate</Tag>
                        }
                    </Fragment>
                );
            }
        },
        {
            title: <Flex justify='center'><Text>Action</Text></Flex>,
            key: 'action',
            width: '100px',
            render: (_, record) => {
                return (
                    < Flex justify='center'>
                        {
                            Number(record?.status) === 2 &&
                            <CommonPopconfirm
                                visible={!!visiblePopconfirm[record?._id]}
                                title={Object.keys(auth?.invitationMsg).length > 0 && auth?.invitationMsg?.title}
                                description={Object.keys(auth?.invitationMsg).length > 0 && auth?.invitationMsg?.description}
                                onConfirm={() => handleConfirm(record?._id)}
                                onCancel={() => handleCancel(record?._id)}
                                icon={null}
                                okText='OK'
                                cancelText='Cancel'
                                cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                onVisibleChange={(visible) => !visible && handleCancel(record?._id)}
                            >
                                <Button
                                    type='text'
                                    className='padding-1'
                                    onClick={() => handleCheckInvitation(record._id)}
                                >
                                    <EmailIcon role='button' />
                                </Button>
                            </CommonPopconfirm>

                        }
                        {
                            Number(record?.status) !== 2 &&
                            <Fragment>
                                <Button
                                    type='text'
                                    className='padding-1'
                                    onClick={() => navigate(`/user/edit/${record?._id}`)}>
                                    <EditIcon role='button' />
                                </Button>
                                {
                                    Number(record?.status) !== 3 ?
                                        <CommonPopconfirm
                                            title='Deactivate the user'
                                            description='Are you sure you want to deactivate this user?'
                                            onConfirm={() => dispatch(deleteUser({
                                                id: record?._id,
                                                sortBy: '',
                                                order: '',
                                                search: '',
                                                page: page,
                                                limit: pageSize,
                                            }))}
                                            icon={null}
                                            cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                        >
                                            <Button
                                                type='text'
                                                className='padding-1'>
                                                <DeleteIcon
                                                    role='button'
                                                />
                                            </Button>
                                        </CommonPopconfirm>
                                        :
                                        <CommonPopconfirm
                                            icon={null}
                                            title='Activate the user'
                                            description='Are you sure you want to activate this user?'
                                            onConfirm={() => dispatch(deleteUser({
                                                id: record?._id,
                                                sortBy: '',
                                                order: '',
                                                search: '',
                                                page: page,
                                                limit: pageSize,
                                            }))}
                                            cancelButtonProps={{ danger: true, size: 'small', type: 'primary' }}
                                        >
                                            <Button
                                                type='text'
                                                className='padding-1'
                                            >
                                                <CloseIcon role='button' />
                                            </Button>
                                        </CommonPopconfirm>
                                }
                            </Fragment>
                        }
                    </Flex >
                );
            },
        },
    ];

    return (
        <Fragment>
            <Flex vertical gap={24} className='change-tag'>
                <Row
                    justify='space-between'
                    align='middle'
                    className='bg-white padding-1 rounded-1'
                    gutter={[16, 16]}
                >
                    <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
                        <Row align='middle' gutter={[16, 16]}>
                            <Col flex='none'>
                                <Title level={4} type='success'>User List</Title>
                            </Col>
                            <Col flex='auto' xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                <Input
                                    value={search}
                                    placeholder='Search here...'
                                    onChange={(e) => setSearch(e.target.value)}
                                    prefix={<SearchIcon color='#000' size={20} />}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
                        <Flex justify='end'>
                            <Button onClick={() => navigate('/user/add')} >Add New User</Button>
                        </Flex>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <DataTable
                            columns={columns}
                            pageSize={pageSize}
                            currentPage={page}
                            total={totalRecords}
                            scrollX={1000}
                            showSorterTooltip={false}
                            isPagination={true}
                            handleOnChange={handleOnChange}
                            data={userList}
                            rowKey={(record) => record?._id}
                        />
                    </Col>
                </Row>
            </Flex>
        </Fragment>
    );
};

export default User;
