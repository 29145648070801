import React, { Fragment, useEffect, useState } from 'react';
import { Layout } from 'antd';

import SidebarComponent from './sidebar';
import HeaderComponent from './header';
import ContenetComponent from './content';
import { jwtDecode } from 'jwt-decode';
import { Link } from 'react-router-dom';
import Icon from '../components/icon/Icon';
import { permission } from '../utils/constraints';
import { setLoginUserId, setLoginUserRole } from '../pages/user/redux/slice';
import { useDispatch } from 'react-redux';

const sideBarRoutes = [
    {
        label: <Link to='/dashboard'>Dashboard</Link>,
        key: '1',
        component: 'dashboard',
        icon: <Icon name='dashboard' iconStr='M9.99984 2.23145C9.23845 2.23145 8.49162 2.44007 7.84046 2.83466L3.6738 5.35966C3.06108 5.73095 2.55444 6.25397 2.20282 6.87819C1.85121 7.50241 1.66649 8.20675 1.6665 8.92318V14.1665C1.6665 15.2716 2.10549 16.3314 2.88689 17.1128C3.66829 17.8942 4.7281 18.3332 5.83317 18.3332H14.1665C15.2716 18.3332 16.3314 17.8942 17.1128 17.1128C17.8942 16.3314 18.3332 15.2716 18.3332 14.1665V8.92235C18.333 8.20608 18.1482 7.50176 17.7966 6.87774C17.445 6.25372 16.9385 5.73087 16.3259 5.35966L12.1592 2.83466C11.5081 2.44008 10.7612 2.23145 9.99984 2.23145ZM8.70422 4.26003C9.09492 4.02328 9.54301 3.89811 9.99984 3.89811C10.4567 3.89811 10.9048 4.02328 11.2954 4.26003L15.4621 6.78503C15.8297 7.00776 16.1336 7.32147 16.3446 7.69589C16.5555 8.07025 16.6664 8.49266 16.6665 8.92235V14.1665C16.6665 14.8296 16.4031 15.4654 15.9343 15.9343C15.4654 16.4031 14.8295 16.6665 14.1665 16.6665H13.3332V14.1665C13.3332 13.2825 12.982 12.4346 12.3569 11.8095C11.7317 11.1844 10.8839 10.8332 9.99984 10.8332C9.11578 10.8332 8.26793 11.1844 7.64281 11.8095C7.01769 12.4346 6.6665 13.2825 6.6665 14.1665V16.6665H5.83317C5.17013 16.6665 4.53424 16.4031 4.0654 15.9343C3.59656 15.4654 3.33317 14.8296 3.33317 14.1665V8.92318C3.33316 8.49332 3.44399 8.07069 3.65496 7.69616C3.86593 7.32163 4.16991 7.00782 4.53755 6.78504L8.70422 4.26003ZM11.1783 12.988C11.4909 13.3006 11.6665 13.7245 11.6665 14.1665V16.6665H8.33317V14.1665C8.33317 13.7245 8.50876 13.3006 8.82132 12.988C9.13388 12.6754 9.55781 12.4999 9.99984 12.4999C10.4419 12.4999 10.8658 12.6754 11.1783 12.988Z' size={28} />
    },
    {
        label: 'User Management',
        key: '2',
        icon: <Icon name='user' iconStr='M9.99992 2.5C8.89485 2.5 7.83504 2.93899 7.05364 3.72039C6.27224 4.50179 5.83325 5.5616 5.83325 6.66667C5.83325 7.77173 6.27224 8.83154 7.05364 9.61294C7.10861 9.66791 7.16495 9.72119 7.22259 9.77273C6.50883 10.0998 5.85173 10.5534 5.28587 11.1193C4.03563 12.3695 3.33325 14.0652 3.33325 15.8333V16.6667C3.33325 17.1269 3.70635 17.5 4.16659 17.5C4.62682 17.5 4.99992 17.1269 4.99992 16.6667V15.8333C4.99992 14.5073 5.5267 13.2355 6.46438 12.2978C7.40207 11.3601 8.67384 10.8333 9.99992 10.8333C11.326 10.8333 12.5978 11.3601 13.5355 12.2978C14.4731 13.2355 14.9999 14.5073 14.9999 15.8333V16.6667C14.9999 17.1269 15.373 17.5 15.8333 17.5C16.2935 17.5 16.6666 17.1269 16.6666 16.6667V15.8333C16.6666 14.0652 15.9642 12.3695 14.714 11.1193C14.1481 10.5534 13.491 10.0998 12.7772 9.77273C12.8349 9.72119 12.8912 9.66791 12.9462 9.61294C13.7276 8.83154 14.1666 7.77173 14.1666 6.66667C14.1666 5.5616 13.7276 4.50179 12.9462 3.72039C12.1648 2.93899 11.105 2.5 9.99992 2.5ZM8.23215 4.8989C8.70099 4.43006 9.33688 4.16667 9.99992 4.16667C10.663 4.16667 11.2988 4.43006 11.7677 4.8989C12.2365 5.36774 12.4999 6.00363 12.4999 6.66667C12.4999 7.32971 12.2365 7.96559 11.7677 8.43443C11.2988 8.90327 10.663 9.16667 9.99992 9.16667C9.33688 9.16667 8.70099 8.90327 8.23215 8.43443C7.76331 7.96559 7.49992 7.32971 7.49992 6.66667C7.49992 6.00363 7.76331 5.36774 8.23215 4.8989Z' size={28} />,
        children: [
            {
                label: <Link to='/user'>Users</Link>,
                component: 'users',
                key: '21'
            },
            {
                label: <Link to='/user/type'>User Type</Link>,
                component: 'userType',
                key: '22'
            }
        ]
    },
    {
        label: <Link to='/client'>Clients</Link>,
        key: '3',
        component: 'clients',
        icon: <Icon name='user' iconStr='M9.99992 2.5C8.89485 2.5 7.83504 2.93899 7.05364 3.72039C6.27224 4.50179 5.83325 5.5616 5.83325 6.66667C5.83325 7.77173 6.27224 8.83154 7.05364 9.61294C7.10861 9.66791 7.16495 9.72119 7.22259 9.77273C6.50883 10.0998 5.85173 10.5534 5.28587 11.1193C4.03563 12.3695 3.33325 14.0652 3.33325 15.8333V16.6667C3.33325 17.1269 3.70635 17.5 4.16659 17.5C4.62682 17.5 4.99992 17.1269 4.99992 16.6667V15.8333C4.99992 14.5073 5.5267 13.2355 6.46438 12.2978C7.40207 11.3601 8.67384 10.8333 9.99992 10.8333C11.326 10.8333 12.5978 11.3601 13.5355 12.2978C14.4731 13.2355 14.9999 14.5073 14.9999 15.8333V16.6667C14.9999 17.1269 15.373 17.5 15.8333 17.5C16.2935 17.5 16.6666 17.1269 16.6666 16.6667V15.8333C16.6666 14.0652 15.9642 12.3695 14.714 11.1193C14.1481 10.5534 13.491 10.0998 12.7772 9.77273C12.8349 9.72119 12.8912 9.66791 12.9462 9.61294C13.7276 8.83154 14.1666 7.77173 14.1666 6.66667C14.1666 5.5616 13.7276 4.50179 12.9462 3.72039C12.1648 2.93899 11.105 2.5 9.99992 2.5ZM8.23215 4.8989C8.70099 4.43006 9.33688 4.16667 9.99992 4.16667C10.663 4.16667 11.2988 4.43006 11.7677 4.8989C12.2365 5.36774 12.4999 6.00363 12.4999 6.66667C12.4999 7.32971 12.2365 7.96559 11.7677 8.43443C11.2988 8.90327 10.663 9.16667 9.99992 9.16667C9.33688 9.16667 8.70099 8.90327 8.23215 8.43443C7.76331 7.96559 7.49992 7.32971 7.49992 6.66667C7.49992 6.00363 7.76331 5.36774 8.23215 4.8989Z' size={28} />,
    },
    {
        label: <Link to='/packages'>Packages</Link>,
        key: '4',
        component: 'packages',
        icon: <Icon name='user' iconStr='M9.99992 2.5C8.89485 2.5 7.83504 2.93899 7.05364 3.72039C6.27224 4.50179 5.83325 5.5616 5.83325 6.66667C5.83325 7.77173 6.27224 8.83154 7.05364 9.61294C7.10861 9.66791 7.16495 9.72119 7.22259 9.77273C6.50883 10.0998 5.85173 10.5534 5.28587 11.1193C4.03563 12.3695 3.33325 14.0652 3.33325 15.8333V16.6667C3.33325 17.1269 3.70635 17.5 4.16659 17.5C4.62682 17.5 4.99992 17.1269 4.99992 16.6667V15.8333C4.99992 14.5073 5.5267 13.2355 6.46438 12.2978C7.40207 11.3601 8.67384 10.8333 9.99992 10.8333C11.326 10.8333 12.5978 11.3601 13.5355 12.2978C14.4731 13.2355 14.9999 14.5073 14.9999 15.8333V16.6667C14.9999 17.1269 15.373 17.5 15.8333 17.5C16.2935 17.5 16.6666 17.1269 16.6666 16.6667V15.8333C16.6666 14.0652 15.9642 12.3695 14.714 11.1193C14.1481 10.5534 13.491 10.0998 12.7772 9.77273C12.8349 9.72119 12.8912 9.66791 12.9462 9.61294C13.7276 8.83154 14.1666 7.77173 14.1666 6.66667C14.1666 5.5616 13.7276 4.50179 12.9462 3.72039C12.1648 2.93899 11.105 2.5 9.99992 2.5ZM8.23215 4.8989C8.70099 4.43006 9.33688 4.16667 9.99992 4.16667C10.663 4.16667 11.2988 4.43006 11.7677 4.8989C12.2365 5.36774 12.4999 6.00363 12.4999 6.66667C12.4999 7.32971 12.2365 7.96559 11.7677 8.43443C11.2988 8.90327 10.663 9.16667 9.99992 9.16667C9.33688 9.16667 8.70099 8.90327 8.23215 8.43443C7.76331 7.96559 7.49992 7.32971 7.49992 6.66667C7.49992 6.00363 7.76331 5.36774 8.23215 4.8989Z' size={28} />,
    },
];

const Wrapper = ({ children }) => {
    const dispatch = useDispatch()

    const [collapsed, setCollapsed] = useState(false);
    const [loginUser, setLoginUser] = useState({});
    const [filteredRoutes, setFilteredRoutes] = useState([]);

    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value?.split(`; ${name}=`);
            if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
            return null;
        }

        const token = getCookie('token');
        if (token) {
            const user = jwtDecode(token);
            setLoginUser(user);
            dispatch(setLoginUserId(user._id))
            dispatch(setLoginUserRole(user.role))
        }
    }, [])

    useEffect(() => {
        if (loginUser) {
            const userType = loginUser?.role;

            const accessibleRoutes = sideBarRoutes.reduce((acc, route) => {
                const hasAccess = permission[route.component]?.includes(userType?.toLowerCase());

                if (hasAccess) {
                    acc.push({ ...route });
                }

                if (route.children) {
                    const accessibleChildren = route.children.filter(child =>
                        permission[child.component]?.includes(userType?.toLowerCase())
                    );

                    if (accessibleChildren.length > 0) {
                        acc.push({ ...route, children: accessibleChildren });
                    }
                }

                return acc;
            }, []);

            setFilteredRoutes(accessibleRoutes);
        }
    }, [loginUser]);

    return (
        <Fragment>
            <Layout className='vh-100 flex gap-1 padding-2'>
                <SidebarComponent collapsed={collapsed} setCollapsed={setCollapsed} filteredRoutes={filteredRoutes} />
                <Layout>
                    <HeaderComponent collapsed={collapsed} setCollapsed={setCollapsed} />
                    <ContenetComponent children={children} />
                </Layout>
            </Layout>
        </Fragment>
    )
}

export default Wrapper;
