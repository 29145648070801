import User from '../pages/user/User';
import AddUpdateUser from '../pages/user/AddUpdateUser';
import CheckMail from '../pages/auth/CheckMail';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';
import SignIn from '../pages/auth/SignIn';
import VerifyOTP from '../pages/auth/VerifyOTP';
import SetPassword from '../pages/auth/SetPassword';
import WelcomePage from '../pages/auth/WelcomePage';
import UserType from '../pages/user-type/UserType';
import UpdateUserType from '../pages/user-type/UpdateUserType';
import Packages from '../pages/packages/Packages';
import AddUpdatePackages from '../pages/packages/AddUpdatePackages';
import VerifyEmail from '../pages/auth/VerifyEmail';
import Client from '../pages/client/Client';
import AddUpdateClient from '../pages/client/AddUpdateClient';

export const privateRoutes = [
    {
        to: '/dashboard',
        element: <p>Dashboard</p>,
        children: []
    },
    {
        to: 'user',
        children: [
            {
                to: '',
                element: <User />
            },
            {
                to: 'add',
                element: <AddUpdateUser />
            },
            {
                to: 'edit/:id',
                element: <AddUpdateUser />
            },
            {
                to: 'type',
                element: <UserType />
            },
            {
                to: 'type/edit/:id',
                element: <UpdateUserType />
            },
        ],
    },
    {
        to: '/client',
        element: <Client />,
        children: [],
    },
    {
        to: '/client/add',
        element: <AddUpdateClient />,
        children: [],
    },
    {
        to: '/client/edit/:id',
        element: <AddUpdateClient />,
        children: [],
    },
    {
        to: '/packages',
        element: <Packages />,
        children: [],
    },
    {
        to: '/packages/add',
        element: <AddUpdatePackages />,
        children: [],
    },
    {
        to: '/packages/edit/:id',
        element: <AddUpdatePackages />,
        children: [],
    },
];

export const publicRoutes = [
    {
        to: 'user/authentication/sign-in',
        element: <SignIn />
    },
    {
        to: 'user/authentication/forgot-password',
        element: <ForgotPassword />
    },
    {
        to: 'user/authentication/verify-otp/:id',
        element: <VerifyOTP />
    },
    {
        to: 'user/authentication/reset-password/:id/:token',
        element: <ResetPassword />
    },
    {
        to: 'user/authentication/check-email',
        element: <CheckMail />
    },
    {
        to: 'user/authentication/set-password',
        element: <SetPassword />
    },
    {
        to: 'user/welcome',
        element: <WelcomePage />
    },
    {
        to: 'user/authentication/verify-email/:id/:token',
        element: <VerifyEmail />
    }
];