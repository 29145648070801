import React, { useEffect, useState } from 'react';

import { Layout, Menu, Image, Flex, Typography } from 'antd';
import { Link } from 'react-router-dom';

import Icon from '../../components/icon/Icon';

import logo from '../../assets/logo/logo.svg';

const { Sider } = Layout;
const { Title, Text } = Typography;

const drawerWidth = 260;

const SidebarComponent = ({ collapsed, setCollapsed, filteredRoutes }) => {
    return (
        <Sider
            className='rounded-2'
            breakpoint='lg'
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={drawerWidth}
            onBreakpoint={(broken) => setCollapsed(broken)}
        >
            <Flex align='center' justify='center' gap={16} className='padding-1'>
                <Image src={logo} preview={false} />
                {!collapsed && <Title type='danger' level={3}>Filesen</Title>}
            </Flex>
            <Flex>
                <Text type='danger paddingX-1'>
                    <small>MAIN</small>
                </Text>
            </Flex>
            <Menu
                mode='inline'
                theme='light'
                className='rounded-2'
                defaultSelectedKeys={['1']}
                items={filteredRoutes}
            />
        </Sider>
    );
};

export default SidebarComponent;
