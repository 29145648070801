import * as React from 'react';

const Icon = ({
    size = 24,
    color = '#000000',
    role = 'default',
    name = 'icon',
    style = {},
    iconStr = '',
    ...Rest
}) => {

    const styles = {
        cursor: 'pointer',
        ...style,
    }

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            id={name}
            viewBox='0 0 24 24'
            width={size}
            height={size}
            style={styles}
            {...Rest}
        >
            <path
                d={iconStr}
                fill={color}
            />
        </svg>
    );
};

export default Icon;
