import React, { Fragment, useEffect, useState } from 'react';

import { Layout, Button, Flex, Avatar, Typography, Space, Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import MenuIcon from '../../components/icon/MenuIcon';

import profilImage from '../../assets/images/profile-Image.png';
import DownArrowIcon from '../../components/icon/DownArrowIcon';
import openNotification from '../../components/notification';

import '../../styles/layout/header.scss';

const { Header } = Layout;
const { Text, Title, Link } = Typography;

const HeaderComponent = ({ collapsed, setCollapsed }) => {
    const navigate = useNavigate();

    const [loginUser, setLoginUser] = useState({});

    const handleLogout = () => {
        document.cookie = `token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        openNotification({ message: 'You have been logged out successfully!', type: 'success' });
        navigate('/user/authentication/sign-in');
    };

    // Decode login user token //
    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value?.split(`; ${name}=`);
            if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
            return null;
        }

        const token = getCookie('token');
        if (token) {
            const user = jwtDecode(token);
            setLoginUser(user);
        }
    }, []);

    const items = [
        {
            key: '0',
            label: <Text onClick={handleLogout} type='danger' className='paddingX-1'>Logout</Text>,
        },
    ];

    return (
        <Fragment>
            <Header className='rounded-top-2 header'>
                <Flex justify='space-between' align='center'>
                    <Button
                        type='text'
                        icon={<MenuIcon role={'button'} />}
                        onClick={() => setCollapsed(!collapsed)}
                        className='h-100 paddingY-1'
                    />
                    <Flex gap={12} className='paddingX-1'>
                        <Avatar
                            src={profilImage}
                            size='large'
                        />
                        <Flex vertical>
                            <Dropdown
                                menu={{
                                    items,
                                    selectable: true,
                                    defaultSelectedKeys: ['0'],
                                }}
                                className='header-dropdown'
                                trigger={['click']}
                            >
                                <Link>
                                    <Space role='button'>
                                        <Title type='success' level={5}>{Object.keys(loginUser).length > 0 && loginUser?.firstName?.charAt(0)?.toUpperCase() + loginUser?.firstName?.slice(1)?.toLowerCase()}</Title>
                                        <DownArrowIcon role='button' size={20} />
                                    </Space>
                                </Link>
                            </Dropdown>
                            <Text type='secondary'>{Object.keys(loginUser).length > 0 && loginUser?.role}</Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Header>
        </Fragment>
    );
};

export default HeaderComponent;
