import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../axios/interceptor';
import openNotification from '../../components/notification';

const initialState = {
    listing: {
        userType: [],
        menu: [],
        menuOption: [],
        packages: []
    },
    loading: false,
    error: null,
};

export const slice = createSlice({
    name: 'listing',
    initialState,
    reducers: {
        getList: (state, action) => {
            const { key, value } = action.payload
            state.error = null;
            state.isUpdating = false;
            state.listing[key] = value;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setIsUpdating: (state, action) => {
            state.isUpdating = action.payload
        }
    },
});

export const getUserTypeListing = createAsyncThunk('getUserTypeListing', (_, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/role/options`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getList({ key: 'userType', value: response.data.records }))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getMenuListing = createAsyncThunk("getMenuListing", (_, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/menu/get`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getList({ key: 'menu', value: response.data.records }))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getMenuOptionListing = createAsyncThunk("getMenuOptionListing", (_, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/menu/options`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getList({ key: 'menuOption', value: response.data.records }))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

export const getPackagesListing = createAsyncThunk("getPackagesListing", (_, { dispatch }) => {
    dispatch(setLoading(true))
    axios.get(`/package/options`).then((response) => {
        if (response.data.isSuccess) {
            dispatch(getList({ key: 'packages', value: response.data.records }))
        } else {
            openNotification({ message: response.data.message, type: 'error' });
        }
    }).catch((error) => {
        openNotification({ message: error.message, type: 'error' });
    }).finally(() => {
        dispatch(setLoading(false))
    })
});

// Action creators are generated for each case reducer function
export const { getList, setError, setLoading, setIsUpdating } = slice.actions;

export default slice.reducer;
