import axios from 'axios';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URI}/api/`, // our API base URL
});

api.interceptors.request.use(
    (config) => {
        const getCookie = (name) => {
            const decodedCookie = decodeURIComponent(document.cookie);
            const cookies = decodedCookie.split(';').map(cookie => cookie.trim());
            const cookie = cookies.find(cookie => cookie.startsWith(`${name}=`));
            return cookie ? cookie.substring(`${name}=`.length) : '';
        };

        const token = getCookie('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

export default api;
