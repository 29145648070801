import React, { Fragment, useEffect, useState } from 'react';

import { Button, Card, Checkbox, Col, Collapse, Divider, Flex, Form, Input, Row, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getByIdUserType, getUserType, setIsSuccess, setIsUpdating, updateUserType } from './redux/slice';
import { getMenuListing } from '../../redux/slice/global';

import BackIcon from '../../components/icon/BackIcon';
import ExpandIcon from '../../components/icon/ExpandIcon';

const { Title, Text } = Typography;

const renderCollapseItem = (item, ind, handleChange) => {
    return [{
        key: `${ind + 1}`,
        label: (
            <Row>
                <Col xxl={9} xl={9} lg={9} md={24} sm={24} xs={24}>
                    <Text>{item.label}</Text>
                </Col>
                <Col xxl={15} xl={15} lg={15} md={24} sm={24} xs={24}>
                    <Flex wrap gap={16} align='center' className='h-100'>
                        <Checkbox
                            checked={item.permissions?.hasAccess}
                            onChange={(e) => handleChange(e, item._id, 'hasAccess')}
                        >Access</Checkbox>
                        <Checkbox
                            checked={item.permissions?.canCreate}
                            onChange={(e) => handleChange(e, item._id, 'canCreate')}
                        >Add New</Checkbox>
                        <Checkbox
                            checked={item.permissions?.canUpdate}
                            onChange={(e) => handleChange(e, item._id, 'canUpdate')}
                        >Edit</Checkbox>
                        <Checkbox
                            checked={item.permissions?.canDelete}
                            onChange={(e) => handleChange(e, item._id, 'canDelete')}
                        >Delete</Checkbox>
                    </Flex>
                </Col>
            </Row>
        ),
        children: item?.children?.length > 0
            ? item.children.map((x, index) => {
                return (
                    <Row key={index}>
                        <Col xxl={9} xl={9} lg={9} md={24} sm={24} xs={24}>
                            <Text className='paddingX-1'>{x.label}</Text>
                        </Col>
                        <Col xxl={15} xl={15} lg={15} md={24} sm={24} xs={24}>
                            <Flex wrap gap={16} align='center' className='h-100 paddingX-3'>
                                <Checkbox
                                    checked={x.permissions?.hasAccess}
                                    onChange={(e) => handleChange(e, x._id, 'hasAccess')}
                                >Access</Checkbox>
                                <Checkbox
                                    checked={x.permissions?.canCreate}
                                    onChange={(e) => handleChange(e, x._id, 'canCreate')}
                                >Add New</Checkbox>
                                <Checkbox
                                    checked={x.permissions?.canUpdate}
                                    onChange={(e) => handleChange(e, x._id, 'canUpdate')}
                                >Edit</Checkbox>
                                <Checkbox
                                    checked={x.permissions?.canDelete}
                                    onChange={(e) => handleChange(e, x._id, 'canDelete')}
                                >Delete</Checkbox>
                            </Flex>
                        </Col>
                    </Row>
                );
            })
            : [],
        showArrow: item?.children?.length > 0
    }];
}

const UpdateUserType = () => {
    const [form] = Form.useForm();
    const { id } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userType, listing } = useSelector(state => state)
    const [selectAll, setSelectAll] = useState(false);
    const [permissionMenu, setPermissionMenu] = useState([])
    const [permissionsState, setPermissionsState] = useState([]);

    useEffect(() => {
        dispatch(getMenuListing())
    }, [])

    useEffect(() => {
        setPermissionMenu(listing.listing.menu);
    }, [listing.listing.menu])

    useEffect(() => {
        if (id) {
            dispatch(setIsUpdating(true))
            dispatch(getByIdUserType(id))
        }
    }, [id])

    useEffect(() => {
        if (Object.keys(userType.userType).length > 0) {
            if (userType.isUpdating) {
                form.setFieldsValue(userType.userType)
                setPermissionsState(
                    userType.userType.menu?.length > 0
                        ? listing.listing.menu?.map(item => {
                            // Find the user's permissions for this menu item
                            const userMenuPermissions = userType?.userType?.menu?.find(i => i.menu === item._id)?.permissions || {};

                            return {
                                ...item,
                                permissions: {
                                    hasAccess: userMenuPermissions.hasAccess || false,
                                    canCreate: userMenuPermissions.canCreate || false,
                                    canUpdate: userMenuPermissions.canUpdate || false,
                                    canDelete: userMenuPermissions.canDelete || false
                                },
                                children: item.children.map(child => {
                                    // Check if the parent menu item's ID matches the condition
                                    if (userType?.userType?.menu?.some(i => i.menu === item._id)) {
                                        // Use the parent's permissions for the child
                                        const userChildPermissions = userMenuPermissions;

                                        return {
                                            ...child,
                                            permissions: {
                                                hasAccess: userChildPermissions.hasAccess || false,
                                                canCreate: userChildPermissions.canCreate || false,
                                                canUpdate: userChildPermissions.canUpdate || false,
                                                canDelete: userChildPermissions.canDelete || false
                                            }
                                        };
                                    } else {
                                        // Default permissions for child if the condition is not met
                                        return {
                                            ...child,
                                            permissions: {
                                                hasAccess: false,
                                                canCreate: false,
                                                canUpdate: false,
                                                canDelete: false
                                            }
                                        };
                                    }
                                })
                            };
                        })
                        : listing.listing.menu.map(item => ({
                            ...item,
                            permissions: {
                                hasAccess: false,
                                canCreate: false,
                                canUpdate: false,
                                canDelete: false
                            },
                            children: item.children.map(child => ({
                                ...child,
                                permissions: {
                                    hasAccess: false,
                                    canCreate: false,
                                    canUpdate: false,
                                    canDelete: false
                                }
                            }))
                        }))
                );
            } else {
                dispatch(updateUserType(userType.userType))
            }
        }
        if (userType.isSuccess) {
            handleClear()
            dispatch(setIsSuccess(false))
            navigate(-1)
        }
    }, [userType.userType, userType.isSuccess, userType.isUpdating])

    const handleOnFinish = (values) => {
        dispatch(setIsUpdating(false))
        dispatch(getUserType({
            ...values, id: id, menu: permissionsState.map(menuItem => ({
                menu: menuItem._id,
                permissions: menuItem.permissions,
                children: menuItem.children.map(child => ({
                    menu: child._id,
                    permissions: child.permissions,
                    _id: child._id
                })),
                _id: menuItem._id
            }))
        }));
    };

    const handleClear = () => {
        form.resetFields();
        setPermissionsState(
            permissionMenu.map(item => ({
                ...item,
                permissions: {
                    hasAccess: false,
                    canCreate: false,
                    canUpdate: false,
                    canDelete: false
                },
                children: item.children.map(child => ({
                    ...child,
                    permissions: {
                        hasAccess: false,
                        canCreate: false,
                        canUpdate: false,
                        canDelete: false
                    }
                }))
            }))
        );
        setSelectAll(false);
    };

    const handleChange = (e, id, permissionType) => {
        const { checked } = e.target;

        const updatePermissions = (items) => {
            return items?.map(item => {
                if (item._id === id) {
                    const newPermissions = {
                        ...item.permissions,
                        [permissionType]: checked,
                        hasAccess: permissionType === 'hasAccess'
                            ? checked
                            : checked || item.permissions.hasAccess
                    };

                    if (permissionType === 'hasAccess' && !checked) {
                        return {
                            ...item,
                            permissions: {
                                hasAccess: false,
                                canCreate: false,
                                canUpdate: false,
                                canDelete: false
                            },
                            children: item.children?.map(child => ({
                                ...child,
                                permissions: {
                                    hasAccess: false,
                                    canCreate: false,
                                    canUpdate: false,
                                    canDelete: false
                                }
                            }))
                        };
                    }

                    if (permissionType !== 'hasAccess' && checked) {
                        return {
                            ...item,
                            permissions: newPermissions,
                            children: item.children?.map(child => ({
                                ...child,
                                permissions: {
                                    hasAccess: newPermissions?.hasAccess,
                                    canCreate: newPermissions?.canCreate,
                                    canUpdate: newPermissions?.canUpdate,
                                    canDelete: newPermissions?.canDelete
                                }
                            }))
                        };
                    }

                    return {
                        ...item,
                        permissions: newPermissions,
                        children: item.children?.map(child => ({
                            ...child,
                            permissions: {
                                ...child.permissions,
                                [permissionType]: checked,
                                hasAccess: permissionType === 'hasAccess'
                                    ? checked
                                    : checked || child.permissions.hasAccess
                            }
                        }))
                    };
                } else {
                    return {
                        ...item,
                        children: updatePermissions(item.children)
                    };
                }
            });
        };

        setPermissionsState(prevState => updatePermissions(prevState));
    };

    const handleSelectAllChange = (e) => {
        const { checked } = e.target;

        // Function to update all items and their children
        const updateAllPermissions = (items) => {
            return items?.map(item => ({
                ...item,
                permissions: {
                    hasAccess: checked,
                    canCreate: checked,
                    canUpdate: checked,
                    canDelete: checked
                },
                children: item.children?.map(child => ({
                    ...child,
                    permissions: {
                        hasAccess: checked,
                        canCreate: checked,
                        canUpdate: checked,
                        canDelete: checked
                    }
                }))
            }));
        };

        setSelectAll(checked);
        setPermissionsState(updateAllPermissions(permissionMenu));
    };

    const customExpandIcon = ({ isActive }) => {
        return <ExpandIcon
            style={{
                transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
            }}
            role='button'
        />
    };

    return (
        <Fragment>
            <Flex vertical gap={12} className='user-management'>
                <Button
                    type='text'
                    icon={<BackIcon role={'button'} />}
                    className='bg-white paddingX-3'
                    onClick={() => {
                        handleClear()
                        navigate(-1)
                    }}
                />
                <Flex>
                    <Card bordered={false} className='w-100'>
                        <Flex vertical gap={24}>
                            <Title level={3} type='success'>Edit Administrator User Type</Title>
                            <Form
                                form={form}
                                className='user-filter'
                                name='user'
                                colon={false}
                                layout='vertical'
                                onFinish={handleOnFinish}
                                requiredMark={false}
                            >
                                <Row gutter={18}>
                                    <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                                        <Form.Item
                                            name='name'
                                            label='User Type Name'
                                            rules={[{ required: true, message: 'User type is required!' }]}
                                        >
                                            <Input placeholder='Please Enter' />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Title level={4} type='danger'>User Type Permissions</Title>

                                <Flex gap={64} align='center' className='paddingY-3'>
                                    <Title level={5}>Access</Title>
                                    <Checkbox
                                        checked={selectAll}
                                        onChange={handleSelectAllChange}
                                    >
                                        Select All
                                    </Checkbox>
                                </Flex>

                                <Divider className='margin-0' />

                                {
                                    permissionsState?.map((i, ind) => (
                                        i?.children?.length > 0
                                            ? <Fragment>
                                                <Collapse
                                                    key={ind}
                                                    items={renderCollapseItem(i, ind, handleChange)}
                                                    bordered={false}
                                                    collapsible='icon'
                                                    expandIcon={customExpandIcon}
                                                />
                                                <Divider className='margin-0' />
                                            </Fragment>
                                            : <Fragment>
                                                <Row className='paddingY-1' key={ind}>
                                                    <Col xxl={9} xl={9} lg={9} md={24} sm={24} xs={24} className='paddingY-1'>
                                                        <Text className='paddingX-3'>{i.label}</Text>
                                                    </Col>
                                                    <Col xxl={15} xl={15} lg={15} md={24} sm={24} xs={24}>
                                                        <Flex wrap gap={16} align='center' className='h-100 paddingX-3'>
                                                            <Checkbox
                                                                checked={i.permissions?.hasAccess}
                                                                onChange={(e) => handleChange(e, i._id, 'hasAccess')}
                                                            >
                                                                Access
                                                            </Checkbox>
                                                            <Checkbox
                                                                checked={i.permissions?.canCreate}
                                                                onChange={(e) => handleChange(e, i._id, 'canCreate')}
                                                            >
                                                                Add New
                                                            </Checkbox>
                                                            <Checkbox
                                                                checked={i.permissions?.canUpdate}
                                                                onChange={(e) => handleChange(e, i._id, 'canUpdate')}
                                                            >
                                                                Edit
                                                            </Checkbox>
                                                            <Checkbox
                                                                checked={i.permissions?.canDelete}
                                                                onChange={(e) => handleChange(e, i._id, 'canDelete')}
                                                            >
                                                                Delete
                                                            </Checkbox>
                                                        </Flex>
                                                    </Col>
                                                </Row>
                                                <Divider className='margin-0' />
                                            </Fragment>
                                    ))
                                }

                                <Flex justify='flex-end' gap={10} wrap={true}>
                                    <Button danger type='primary' htmlType='button' onClick={handleClear}>Clear All</Button>
                                    <Button type='primary' htmlType='submit' loading={userType?.loading && userType?.loading}>Submit</Button>
                                </Flex>
                            </Form>
                        </Flex>
                    </Card>
                </Flex>
            </Flex>
        </Fragment>
    );
};

export default UpdateUserType;
